import React from 'react'
import Link from 'gatsby-link'
import './cta.css'


const CTA = props => (
<div className="CTA">
    <a target="_blank" rel="noopener noreferrer" href={props.externallink}>
        <div className="ctaButton">
            <h2>{props.textlegend}</h2>
        </div>
        </a>
    
</div>
)

export default CTA