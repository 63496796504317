import React from 'react'
import Link from 'gatsby-link'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Img from 'gatsby-image';
import Caption from '../components/caption';
import NextProj from '../components/nextproject';
import CTA from '../components/cta';

import Layout from '../components/layout'
import '../components/layout.css'
import '../components/projectCards.css'



const VSLS = (props) => (
  <Layout>
      <Helmet
      title="Juan Herrera — Microsoft Visual Studio Live Share"
      meta={[
        { name: 'description', content: 'Juan Herrera — Product designer' },
        { name: 'keywords', content: 'product, design, ui, ux' },
      ]}
      />
    <div className="casestudies">
      <div className="casestudyTop">
      <div className="back"><p><Link to="/">Back</Link></p></div>
        <div className="topcontainer">
          <div className="casestudyintro">
            <h2>Microsoft Visual Studio <br />Live Share</h2>
            <p>Microsoft and STRV began collaborating in Q3 2017 with the intent to design multiple development tools. The first was an extension for Visual Studio Code and Visual Studio that ultimately was released as Live Share.</p>
          </div>
          <div id="CSinfo">
            <div className="projectInfo"><h5>Role</h5>
              <p>UI/UX designer<br /></p>
              <h5>Platform</h5>
              <p>Desktop</p>
              <h5>Year</h5>
              <p>2017–2018</p>
            </div>
          </div>
        </div>
      </div>
        <div className="desktopImages">
              <Img alt="VSLS Hero" fluid={props.data.vsls1.childImageSharp.fluid} />
        </div>
        <Caption captiontext="The final Live Share panel. Wallpaper by Preston Pownell."/>
      <div className="dividers">Overview</div>
        <div className="casestudyContent">
            <div className="CSleftCol">
              <p>Originally a Product Manager-driven project with no designers on board, I lead a small team—three people—that worked on developing a set of explorations focused on providing a modern experience for developers looking to collaborate in real time.</p>
              </div>
            <div className="CSrightCol">
              <p>The initial phase of the project consisted of three months of heavy explorations of multiple scenarios. And the second phase, initially, consisted of me taking our learnings and working together with Microsoft Product Managers to bring those features to the then current version of Live Share.</p>
            </div>
        </div>
            <div className="desktopImages">
              <Img alt="VSLS Guest" fluid={props.data.vsls2.childImageSharp.fluid} />
           </div>
           <Caption captiontext="Live Share — Guest’s perspective"/>

      <div className="casestudyContent">
      <div className="CSleftCol">
        <p>Taking cues from popular collaboration tools like Figma, Google Docs or Dropbox Paper, users of Live Share can see ("follow") their collaborators' actions in real time. That includes their current position within a document (and indeed, the project), editing and text highlighting.</p>
        </div>
      </div>

           
            <div className="desktopImages">
              <Img alt="VSLS Follow" fluid={props.data.vsls7.childImageSharp.fluid} />
            </div>
            <Caption captiontext="Due to UI constraints, the way to identify a user that is currently being followed is by using fills instead of strokes in the circle indicator. “Following” also triggers a temporary tab where all the followed user’s action happens."/>
            
            <div className="casestudyContent">
            <div className="CSleftCol">
              <p>The main focus of our explorations was awareness. “Where are the people in my session?”, “Can people look at all the files in my project?”, or “How can I interact with these collaborators?” were some of the questions we were trying to answer in the process.</p>
              </div>
            <div className="CSrightCol">
              <p>Due to the STRV reorg, I had to step down as an active designer for this project. However, I kept overseeing the work until Live Share went back to be worked on internally at Microsoft. <br /><br />My team’s explorations paved the way for further design explorations by Microsoft’s internal teams to optimise this exciting product now that it’s out.</p>
            </div>
          </div>
            
            <div className="desktopImages">
              <Img alt="VSLS Early 1" fluid={props.data.vsls3.childImageSharp.fluid} />
            </div>
            <Caption captiontext="Early explorations featuring a Live Share (or Code Live, as the concept was called) view on the sidebar, before they were officially available for extension developers. Lots of UI components!"/>
            <div className="desktopImages">
              <Img alt="VSLS Early 2" fluid={props.data.vsls4.childImageSharp.fluid} />
            </div>
            <Caption captiontext="One of our early explorations included the idea of showing user viewports in the minimap. Pretty cool, but not necessarily useful and probably nearly impossible to implement."/>
            <div className="desktopImages">
              <Img alt="VSLS Early 3" fluid={props.data.vsls5.childImageSharp.fluid} />
            </div>
            <Caption captiontext="Another early idea was to redirect collaborators to the project's repository on Github upon session completion."/>
            <div className="desktopImages">
              <Img alt="VSLS Early 4" fluid={props.data.vsls6.childImageSharp.fluid} />
            </div>
            <Caption captiontext="Initially, the 'following' feature had influences from tools such as Figma or Google Docs."/>
            
            <CTA
            externallink='https://visualstudio.microsoft.com/services/live-share/'
            textlegend='See it live'
            />

            <NextProj
            nextproj='Beddr'
            projdesc='Beyond sleep tracking.'
            nextlink='/beddr'
            />
            </div>

  </Layout>
)

export default VSLS


// Image stuff

export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid(quality: 95, maxWidth: 1160) {
      ...GatsbyImageSharpFluid_noBase64
    }
  }
}
`;

export const pageQuery = graphql`
  query {
    vsls1: file(relativePath: { eq: "vsls-01.jpg" }) {
      ...fluidImage
    }
    vsls2: file(relativePath: { eq: "vsls-02.png" }) {
      ...fluidImage
    }
    vsls3: file(relativePath: { eq: "vsls-03.png" }) {
      ...fluidImage
    }
    vsls4: file(relativePath: { eq: "vsls-04.png" }) {
      ...fluidImage
    }
    vsls5: file(relativePath: { eq: "vsls-05.png" }) {
      ...fluidImage
    }
    vsls6: file(relativePath: { eq: "vsls-06.png" }) {
      ...fluidImage
    }
    vsls7: file(relativePath: { eq: "vsls-07.png" }) {
      ...fluidImage
    }

  }
`
